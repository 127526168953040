import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardHeader, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import { InstagramEmbed } from 'react-social-media-embed';

const DanceClips = () => {
    return <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
        <Grid item justifyContent="center" alignItems="center">
            <Card>
                <CardHeader title = 'Send your Dance Clips!'/>
                <CardContent>
                    <Typography variant={"body2"}>
                        Send your dance clips and get featured on <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/meanwhile.elsewhere/'> @meanwhile.elsewhere </a>
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <InstagramEmbed url='https://www.instagram.com/p/DCCzCQaJtoD/' width='fluid' captioned/>
                    </div>

                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default DanceClips;