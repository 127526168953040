import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

const About = () => {
    return <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
        <Grid item justifyContent="center" alignItems="center">
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>
                        About
                    </Typography>
                    <Typography sx={{ mb: 2.5 }} color="text.secondary">
                        Hello, this is a page for organizing latin and other social dance events in Austin.
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default About;