import Grid from "@mui/material/Grid";
import React, {useState, useEffect, useCallback} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardHeader, Typography} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { InstagramEmbed } from 'react-social-media-embed';
import { useParams, useNavigate } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CALENDAR_URL = process.env.REACT_APP_CALENDAR_URL
var utc = require("dayjs/plugin/utc");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(utc);
dayjs.extend(customParseFormat);


function DanceDay (props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '💃' : undefined}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function DanceDayInfo (props) {
    const {events} = props;

    if (events && events.length > 0 ) {
        return (
            <Grid container direction='column' alignItems='center' justify='space-evenly' style={{ minHeight: '100vh'}} spacing={3}>
                {events.map((event) => (
                    <Card key={event.location}>
                        <CardHeader title = {event.location}/>
                        <CardContent>
                            <Typography sx={{ mb: 1.5, width : '95%', justifyContent: 'center', variant:"body2" }} color="text.secondary">
                                {event.start} to {event.end}
                            </Typography>
                            <a target='_blank' rel='noopener noreferrer' href={event.address}>Get There</a>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {event.igLink ? <InstagramEmbed url={event.igLink} width='fluid' captioned/> : null}
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </Grid>
        );
    }
    else {
        return (
            <Grid container direction='column' alignItems='center' justify='space-evenly' style={{ minHeight: '100vh'}} spacing={3}>
                    <Card key='no events found'>
                        <CardHeader title = 'No events found for this day!'/>
                        <CardContent>
                            <Typography sx={{ mb: 1.5, width : '95%', justifyContent: 'center', variant:"body2" }} color="text.secondary">
                                No dancing on this date, unfortunately. Try another day!
                            </Typography>
                        </CardContent>
                    </Card>
            </Grid>
        );
    }
}

const Calendar = (props) => {
    // defaults to today
    var calendarDate = dayjs();

    // The Calendar with dance events. Basically the dance Days
    const [calendar, setCalendar] = useState(null);
    // The dance genres available in the calendar
    const [availableDanceGenres, setAvailableDanceGenres] = useState([]);
    // The day to display. calendarDate comes from the props. CalendayDay is an object with a dayjs() and eventList 
    const [calendarDay, setCalendarDay] = useState({day:calendarDate,events:null});
    // Keep track of loading state
    const [isLoading, setIsLoading] = useState(false);
    // The dates to highlight on the calendar
    const [highlightedDays, setHighlightedDays] = useState([]);
    // The selected genres. By default, include salsa and bachata
    const [selectedDanceGenres, setSelectedDanceGenres] = useState(['salsa','bachata']);

    const { dateString } = useParams();
    const navigate = useNavigate()

    if (dateString) {
        const isValidDate = dayjs(dateString, "YYYY-MM-DD",true)
        if (isValidDate) {
            calendarDate = dayjs(dateString)
        }

    }

    function getStyles(genre, selectedDanceArrs = selectedDanceGenres, theme) {
    return {
        fontWeight: selectedDanceArrs.includes(genre)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
    }

    function GenreSelectCheckmarks(props) {
        const {availableDanceGenres= []} = props;
        const theme = useTheme();

        const handleDanceGenreChange = (event) => {
            setIsLoading(true)
            const {
                target: { value },
            } = event;
            // On autofill we get a stringified value.
            const selectedGenresArr = typeof value === 'string' ? value.split(',') : value;

            // Get the day events with the new selecte dance genres
            const daysEvents = getEventsForDay(calendarDate, calendar, selectedGenresArr)
            setCalendarDay({day:calendarDate, ...daysEvents})

            // Refresh the days to highlight
            const daysToHighlight = getDaysToHighlight(calendar, selectedGenresArr)
            setHighlightedDays(daysToHighlight)
            setSelectedDanceGenres(
                selectedGenresArr
            );

            setIsLoading(false)
        };

        return (
            <div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="dance-genre-multiple-chip-label">Dance Style</InputLabel>
                    <Select
                    labelId="dance-genre-multiple-chip-label-id"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedDanceGenres}
                    onChange={handleDanceGenreChange}
                    input={<OutlinedInput id="select-multiple--genre-chip" label="Genre" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                    {availableDanceGenres.map((genre) => (
                        <MenuItem
                        key={genre}
                        value={genre}
                        style={getStyles(genre, selectedDanceGenres, theme)}
                        >
                        {genre}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
        );
    }

    const getEventsForDay = useCallback((dayToFilterFor=calendarDate,calendarEvents=calendar, selectedDanceGenresArr = selectedDanceGenres ) => {
        // We are given a day object
        // Return an array with the events for the day
        if (calendarEvents){
            // Need to filter by genre here
            const eventsArr = calendarEvents.filter(calDay => calDay.day?.isSame(dayToFilterFor, 'day') && calDay.genre?.some(r => selectedDanceGenresArr.includes(r)));
            return {events: eventsArr};
        }
        // return empty array if we can't get events
        return {events:[]}
    },[calendar, selectedDanceGenres, calendarDate]);

    const parseCalendarEvent = (event) => {
        // Parse the start and end timestamps
        const startTs = dayjs(event.start)?.format('h:mm A')
        const endTs = dayjs(event.end)?.format('h:mm A')
        const date = dayjs(event.start)?.utc('z').local().format('MMMM D, YYYY')
        if (startTs){
            event.start = startTs
        }
        if (endTs){
            event.end = endTs
        }
        if (date){
            event.date = date
        }


        const day = {day: dayjs(event.date),...event};
        return day;
    }

    const getDaysToHighlight = useCallback((calendarEvents = calendar, selectedDanceGenresArr = selectedDanceGenres) => {
        // Get the highlightedDays. Highlight only events whose genre match the selected dance genres
        const daysToHighlight = calendarEvents.filter(
            (calendarEvent) => (calendarEvent.day.month() === calendarDate.month() &&
                calendarEvent.genre?.some(r => selectedDanceGenresArr.includes(r))
        )
        ).map(
            (calendarEvent) => calendarEvent.day.date()
        );

        return daysToHighlight;

    },[calendar,calendarDate,selectedDanceGenres]);

    const parseCalendarEvents = useCallback((calendar) => {
        const calendarEvents = calendar.map(
            (event) => parseCalendarEvent(event)
        );
        // The dance genres available by all events in the calendar
        var availableDanceGenresArr = []
        calendar.forEach(
            (event) => {
                // eventGenres is an array
                const eventGenres = event.genre
                availableDanceGenresArr =  availableDanceGenresArr.concat(eventGenres)
            }
        )
        // dedup the danceGenres
        availableDanceGenresArr = [...new Set(availableDanceGenresArr)]

        // now set the state vars
        setCalendar(calendarEvents);
        setAvailableDanceGenres(availableDanceGenresArr)

        const daysToHighlight = getDaysToHighlight(calendarEvents)

        setHighlightedDays(daysToHighlight);

        return calendarEvents;
    }, [getDaysToHighlight]);

    const loadCalendar = useCallback((f, monthNumber, yearNumber, day=calendarDate) => {
        axios.get(f, {params:{month:monthNumber, year:yearNumber, includeRepeating:true}}).then((res) => {
            const calendarEvents = parseCalendarEvents(res.data.events);
            setCalendar(calendarEvents);
            const daysEvents = getEventsForDay(calendarDate,calendarEvents)
            setCalendarDay({day:calendarDate, ...daysEvents})
            return calendarEvents;
        })
        .catch(error => {
            console.error(error)
        });
    },[parseCalendarEvents,calendarDate, getEventsForDay]);

    useEffect(() => {
        setIsLoading(true);
        try {
            // load the calendar for today's month 
            if (!calendar) {
                // We're loading the calendar for the first time, so go ahead and set the curr day to today
                // Months are zero indexed, so add 1            
                loadCalendar(CALENDAR_URL, calendarDate.month() + 1, calendarDate.year(), calendarDate)
            }
            setIsLoading(false);
        } catch (error){
            setIsLoading(false);
            console.error('Unable to fetch the calendar',error);
        }
    }, [calendar, loadCalendar, calendarDay, calendarDate, selectedDanceGenres, getEventsForDay]); 

    const handleCalendarDayChange = (dayObj) => {
        // Set the new calendar day
        const events = getEventsForDay(dayObj);
        setCalendarDay({day:dayObj,...events});
        navigate('/calendar/' + dayObj.format('YYYY-MM-DD'))
    };

    const handleMonthChange = (event) => {
        // Refetch the calendar for the month we're changing to
        const startOfMonth = event.startOf('month')
        const today = dayjs()
        setIsLoading(true);
        // set the calendarDay to the later of the start of the month and today
        calendarDate = today;
        if (startOfMonth.isAfter(today)) {
            calendarDate = startOfMonth
        }
        try {
            // get the calendar for the month we're changing to. Also pass in the year. Months are zero indexed, so add 1
            loadCalendar(CALENDAR_URL, calendarDate.month() + 1, calendarDate.year(), calendarDate);
            navigate('/calendar/' + calendarDate.format('YYYY-MM-DD'))
            setIsLoading(false);
        } catch (error){
            setIsLoading(false);
            console.error('Unable to fetch the calendar',error);
        }
    };

    return (
        <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
            <Card>
                <CardContent>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Hello, I created this page as a way to better keep up with Latin and other social dance events in Austin. Hopefully you'll find it useful as well!
                    </Typography>
                </CardContent>
            </Card>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GenreSelectCheckmarks availableDanceGenres={availableDanceGenres}/>
                <DateCalendar
                    views={['day']}
                    value={calendarDay.day}
                    loading={isLoading} 
                    onChange={handleCalendarDayChange}
                    // set the max date to the end of next month
                    maxDate={dayjs().add(1,'month').endOf("month")}
                    disablePast={true}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots = {{
                        day: DanceDay,
                    }}
                    slotProps={{
                        day: {
                            highlightedDays,
                        },
                    }}
                    />
                    {calendarDay && calendarDay.events ? <DanceDayInfo events={calendarDay.events}/> : null}
            </LocalizationProvider>
        </Grid>
    );
}

export default Calendar;