import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

const TwoStep = () => {
    return <Grid container direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
        <Grid item justifyContent="center" alignItems="center">
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>
                        2 Stepping in Austin 
                    </Typography>
                    <Typography sx={{ mb: 2.5 }} color="text.secondary" variant="body2">
                        In addition to being the live music capital of the world, Austin is likely also the 2 stepping capital of the world (not sorry, Nashville).
                        There is live music and dancing every night of the week. Seriously. So much that it's too much to list on this page. 
                        Thankfully, there is a great resource for your 2 stepping plans available at
                    </Typography>
                    <a target='_blank' rel='noopener noreferrer' href='https://austin2step.com'>Austin2Step.com</a>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default TwoStep;
