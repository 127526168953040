//import './App.css';
import React from 'react';

// Import components
import About from "./component/About.jsx"
import Contact from "./component/Contact.jsx"
import Attendance from "./component/Attendance.jsx"
import Calendar from "./component/Calendar.jsx"
import DrawerBarApp from './component/DrawerBarApp.jsx'
import Home from "./component/Home.jsx"
import DanceClips from "./component/DanceClips.jsx"
import TwoStep from './component/TwoStep.jsx';

// Import React Dom
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";


// Import material UI
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';


// use dark theme
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
let dimensions = {};

if (window.innerWidth < 600) {
    dimensions = {
        headerHeight: "135px",
        banner: "url(/banner-logo.png)",
        width: "100%",
        stickyAt: "-70px"
    }
} else {
    dimensions = {
        headerHeight: "305px",
        banner: "url(/banner-logo.png)",
        width: "1080px",
        stickyAt: "-170px"
    }
}

export const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <Container style={{backgroundColor: "#393536", padding: 0, width: dimensions.width}}>
            <DrawerBarApp/>
            <div style={{
                maxWidth: "100%",
                objectFit: "scale-down",
                height: dimensions.headerHeight,
                overflow: "hidden",
                backgroundImage: dimensions.banner,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
                borderStyle: "solid",
                borderColor: "black",
              }}>
            </div>
            <div style={{padding: '0px 20px 20px 20px'}}>
              <BrowserRouter>
                <Routes>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/attendance" element={<Attendance/>}/>
                  <Route path="/calendar/:dateString?" element={<Calendar/>}/>
                  <Route path="/dance-clips" element={<DanceClips/>}/>
                  <Route path="/two-step" element={<TwoStep/>}/>
                  <Route path="/" element={<Calendar/>}/>
                </Routes>
              </BrowserRouter>
            </div>
        </Container>
    </ThemeProvider>
    );
};

export default App;
