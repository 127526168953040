import About from "../component/About.jsx"
import Contact from "../component/Contact.jsx"
import Calendar from "../component/Calendar.jsx"
import Home from "../component/Home.jsx"
import DanceClips from "../component/DanceClips.jsx"
import TwoStep from "../component/TwoStep.jsx"

const RoutesList = [
  {
    path: '/',
    sidebarName: 'Home',
    component: Calendar 
  },
  {
    path: '/calendar',
    sidebarName: 'Calendar',
    component: Calendar
  },
  {
    path: '/about',
    sidebarName: 'About',
    component: About 
  },
  {
    path: '/contact',
    sidebarName: 'Contact',
    component: Contact 
  },
  {
    path: '/dance-clips',
    sidebarName: 'Dance Clips',
    component: DanceClips
  },
  {
    path: '/two-step',
    sidebarName: '2 Stepping',
    component: TwoStep
  }
];

export default RoutesList;